import React, {
  FC,
  useState,
  Fragment,
  ReactElement,
  useCallback,
  useEffect
} from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Grid,
  TextField,
  Box,
  Button,
  Typography,
  Checkbox
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Pagination from '../components/Pagination'
import Content from '../components/Content'
import SelectFilter from '../components/SelectFilter'
import QueryHandler from '../components/QueryHandler'
import Paperbase from '../components/Paperbase'
import Price from '../components/Price'
import Modal from '../components/Modal'
import ProductMerger from '../components/ProductMerger'
import { GET_PRODUCT_GROUP_LIST, GET_CATEGORY_LIST } from '../graphql/queries'
import { GetProductGroupListQuery, GetCategoryListQuery } from '../graphql/schema'
import { formatDate, formatTime, mergeQueryParams } from '../utils'
import usePageNumber from '../hooks/usePageNumber'
import useSearchTerm from '../hooks/useSearchTerm'
import useQueryParams from '../hooks/useQueryParams'
import styles from './ProductList.module.css'

type ProductGroup = GetProductGroupListQuery['productGroups']['nodes'][0]

const columns = [
  { name: 'Id' },
  { name: 'Image' },
  { name: 'Title', key: 'TITLE' },
  { name: 'Category', key: 'CATEGORY' },
  { name: 'Family', key: 'SUBCATEGORY' },
  { name: 'Brand', key: 'BRAND' },
  { name: 'Created', key: 'CREATED' },
  { name: 'Updated', key: 'UPDATED' },
  { name: 'Visible to' },
  { name: 'Tags' },
  { name: 'Price', key: 'LOH_PRICE' },
  { name: '' }
]

const ProductList: FC<RouteComponentProps> = () => {
  const qs = useQueryParams()
  const page = usePageNumber()
  const [selected, setSelected] = useState<ProductGroup[]>([])
  const [showModal, setShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useSearchTerm()
  const [sort, setSort] = useState({
    name: 'Created',
    key: 'CREATED',
    asc: false
  })

  const onToggleChecked = useCallback(
    (group: ProductGroup) => {
      setSelected(
        selected.some(({ id }) => id === group.id)
          ? selected.filter(({ id }) => id !== group.id)
          : [...selected, group]
      )
    },
    [selected, setSelected]
  )

  // clear subcategory and brand selection when parent category changes
  useEffect(() => {
    mergeQueryParams({ family: undefined, brand: undefined })
  }, [qs.category])

  const onChangeSort = ({ name, key }: { name: string, key: string }): void => {
    setSort({ name, key, asc: key === sort.key ? !sort.asc : true })
  }

  const categoriesQuery = useQuery<GetCategoryListQuery>(GET_CATEGORY_LIST)
  const categories = categoriesQuery.data?.categories ?? []

  const query = useQuery<GetProductGroupListQuery>(GET_PRODUCT_GROUP_LIST, {
    variables: {
      input: {
        page,
        perPage: 25,
        query: searchTerm,
        categoryId: qs.category,
        subcategoryId: qs.family,
        brandId: qs.brand,
        sort: `${sort.key}_${sort.asc ? 'ASC' : 'DESC'}`
      }
    }
  })

  const aggs = query.data?.productGroups.aggregations
  const subcategories = (aggs?.subcategories ?? []).map((x) => x.subcategory)
  const brands = (aggs?.brands ?? []).map((x) => x.brand)

  const topContent = (
    <Grid container spacing={2} alignItems='center'>
      <Grid item>
        <SearchIcon color='inherit' />
      </Grid>
      <Grid item xs>
        <TextField
          defaultValue={searchTerm}
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Search by product title or description'
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
    </Grid>
  )

  const render = (data: GetProductGroupListQuery): ReactElement => {
    const groups = data.productGroups.nodes
    const pageInfo = data.productGroups.pageInfo

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selected.length > 0}
                  onChange={() => setSelected(selected.length ? [] : groups)}
                />
              </TableCell>
              {columns.map((col) => (
                <TableCell key={col.name}>
                  {col.key ? (
                    <TableSortLabel
                      active={sort.key === col.key}
                      direction={sort.asc ? 'desc' : 'asc'}
                      onClick={() => col.key && onChangeSort(col)}
                    >
                      {col.name}
                    </TableSortLabel>
                  ) : (
                    col.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell>
                  <Checkbox
                    value={group.id}
                    checked={selected.some(({ id }) => id === group.id)}
                    onChange={() => onToggleChecked(group)}
                  />
                </TableCell>
                <TableCell>
                  {group.products.map(({ id }) => (
                    <Fragment key={id}>
                      {id}
                      <br />
                    </Fragment>
                  ))}
                </TableCell>
                <TableCell>
                  {group.images[0] ? (
                    <img
                      alt=''
                      src={group.images[0].thumbnailUrl}
                      className={styles.image}
                    />
                  ) : (
                    <Typography variant='body2' color='error'>
                      No images
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{group.title}</TableCell>
                <TableCell>{group.category.title}</TableCell>
                <TableCell>{group.subcategory?.title}</TableCell>
                <TableCell>{group.brand?.title}</TableCell>
                <TableCell>
                  {formatDate(group.createdAt)}
                  <br />
                  {formatTime(group.createdAt)}
                </TableCell>
                <TableCell>
                  {formatDate(group.updatedAt)}
                  <br />
                  {formatTime(group.updatedAt)}
                </TableCell>
                <TableCell>
                  {group.lyfOgHeilsa && (
                    <>
                      Lyf & Heilsa
                      <br />
                    </>
                  )}
                  {group.apotekarinn && (
                    <>
                      Apótekarinn
                      <br />
                    </>
                  )}
                  {!group.lyfOgHeilsa && !group.apotekarinn && (
                    <Typography variant='body2' color='error'>
                      None
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{group.tags.join(', ')}</TableCell>
                <TableCell align='right'>
                  <Price
                    price={group.price}
                    discountPrice={group.discountPrice}
                    separator={<br />}
                  />
                </TableCell>
                <TableCell align='right'>
                  <Link className='inherit' to={`/products/edit/${group.id}`}>
                    <Button variant='contained' color='primary'>
                      Edit
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box paddingTop={5} />
        <Grid container>
          <Grid item xs={3}>
            <Button
              disabled={selected.length < 2}
              onClick={() => setShowModal(true)}
            >
              Merge {selected.length > 0 && selected.length} products
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='center'>
              <Pagination page={page} count={pageInfo.totalPages} />
            </Box>
          </Grid>
        </Grid>
      </TableContainer>
    )
  }

  return (
    <Paperbase>
      <Content size='large' topContent={topContent}>
        <Grid container direction='row' alignItems='center' spacing={3}>
          <Grid item xs={3}>
            <SelectFilter
              label='Category'
              name='category'
              items={categories.map((c) => ({ text: c.title, value: c.id }))}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectFilter
              label='Family'
              name='family'
              items={subcategories.map((c) => ({ text: c.title || c.id, value: c.id }))}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectFilter
              label='Brand'
              name='brand'
              items={brands.map((c) => ({ text: c.title || c.id, value: c.id }))}
            />
          </Grid>
        </Grid>
        <Box paddingTop={3}>
          <QueryHandler query={query} render={render} />
        </Box>
      </Content>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ProductMerger
          groups={selected}
          onSuccess={() => {
            setShowModal(false)
            setSelected([])
            query.refetch()
          }}
        />
      </Modal>
    </Paperbase>
  )
}

export default ProductList

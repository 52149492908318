import { useState, useMemo, useEffect } from 'react'
import debounce from 'lodash/debounce'

const useDebouncedValue = <T>(inputValue: T, delay = 300): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(inputValue)

  const onInputValueChange = useMemo(() => {
    return debounce(setDebouncedValue, delay)
  }, [setDebouncedValue, delay])

  useEffect(() => {
    onInputValueChange(inputValue)
  }, [onInputValueChange, inputValue])

  return debouncedValue
}

export default useDebouncedValue

import React, { FC, useState, useMemo, useCallback } from 'react'
import trim from 'lodash/trim'
import { useMutation } from '@apollo/client'
import { GetProductGroupListQuery } from '../graphql/schema'
import { Box, TextField, Typography, Button } from '@material-ui/core'
import { JOIN_PRODUCT_GROUPS } from '../graphql/mutations'

export interface ProductMergerProps {
  groups: GetProductGroupListQuery['productGroups']['nodes']
  onSuccess: () => void
}

const trimTitle = (s: string): string => trim(s, ' -')
const isMatch = (a?: string, b?: string): boolean =>
  Boolean(a && b && a.toLowerCase() === b.toLowerCase())

const longestCommonPrefix = (strings: string[]): string => {
  const r: string[] = []
  const splits = strings.map((s) => s.split(' '))
  for (let i = 0; splits.every((xs) => isMatch(xs[i], splits[0][i])); i++) {
    r.push(splits[0][i])
  }
  return r.join(' ')
}

const getInitialTitles = (initialTitles: string[]): [string, string[]] => {
  const prefix = longestCommonPrefix(initialTitles).trim()
  if (prefix) {
    const titles = initialTitles.map((s) => s.substring(prefix.length))
    return [trimTitle(prefix), titles.map(trimTitle)]
  }
  return ['', initialTitles]
}

const ProductMerger: FC<ProductMergerProps> = ({ groups, onSuccess }) => {
  const products = useMemo(() => groups.flatMap((group) => group.products), [
    groups
  ])

  const [initialTitle, initialProductTitles] = useMemo(() => {
    return getInitialTitles(products.map((p) => p.title))
  }, [products])

  const [title, setTitle] = useState(initialTitle)
  const [titles, setTitles] = useState(initialProductTitles)

  const [doJoin] = useMutation(JOIN_PRODUCT_GROUPS, {
    update: (cache) => {
      cache.reset()
    },
    onCompleted: onSuccess
  })

  const valid = Boolean(title) && titles.every(Boolean)

  const submit = useCallback(() => {
    doJoin({
      variables: {
        input: {
          title: title,
          productGroupIds: groups.map(({ id }) => id),
          products: products.map(({ id }, i) => ({ id, title: titles[i] }))
        }
      }
    })
  }, [doJoin, title, titles, groups, products])

  return (
    <div>
      <Box pb={2}>
        <Typography variant='h6'>Main product title</Typography>
      </Box>
      <TextField
        name='title'
        fullWidth
        error={!title}
        variant='filled'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Box pt={2}>
        <Typography variant='h6'>Variants</Typography>
      </Box>
      {titles.map((title, i) => (
        <Box key={i} py={2}>
          <TextField
            name='title'
            label={`${products[i]?.id}: ${products[i]?.title}`}
            fullWidth
            error={!title}
            variant='outlined'
            value={title}
            onChange={(e) => {
              const newTitles = titles.slice()
              newTitles[i] = e.target.value
              setTitles(newTitles)
            }}
          />
        </Box>
      ))}
      <Button
        disabled={!valid}
        variant='contained'
        color='primary'
        onClick={submit}
      >
        Confirm merge
      </Button>
    </div>
  )
}

export default ProductMerger

import useQueryParams from './useQueryParams'

export default function usePageNumber (): number {
  const { page } = useQueryParams()
  if (typeof page !== 'string') {
    return 1
  }
  const pageNumber = Number(page)
  return isNaN(pageNumber) ? 1 : pageNumber
}

import { GetMetadataQuery } from '../graphql/schema'
import { useQuery } from '@apollo/client'
import { GET_METADATA } from '../graphql/queries'

export default function useMetadata (): GetMetadataQuery['navMetadata'] {
  const query = useQuery<GetMetadataQuery>(GET_METADATA)
  const data = query.data?.navMetadata
  return {
    familyCodes: data?.familyCodes ?? [],
    categoryCodes: data?.categoryCodes ?? [],
    groups: data?.groups ?? []
  }
}

import { useMemo } from 'react'
import useQueryParams from './useQueryParams'
import { mergeQueryParams } from '../utils'
import debounce from 'lodash/debounce'

export default function useSearchTerm (
  name = 'q',
  delay = 300
): [string, (v: string) => void] {
  const qs = useQueryParams()

  const onChange = useMemo(() => {
    return debounce((value: string) => {
      mergeQueryParams({
        [name]: value || undefined,
        page: undefined
      })
    }, delay)
  }, [name, delay])

  return [qs[name] ?? '', onChange]
}

import React, { FC, Fragment } from 'react'
import cn from 'classnames'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import Drawer, { DrawerProps } from '@material-ui/core/Drawer'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BathtubIcon from '@material-ui/icons/Bathtub'
import { Link } from '@reach/router'
import styles from './Navigator.module.css'

const navigation = [
  {
    id: 'Products',
    subNav: [
      {
        text: 'Products',
        path: '/',
        icon: <AssignmentIcon />
      },
      {
        text: 'Products in NAV',
        path: '/nav-products',
        icon: <BathtubIcon />
      }
    ]
  }
]

export const Navigator: FC<DrawerProps> = (props) => {
  return (
    <Drawer variant='permanent' {...props}>
      <List disablePadding>
        <ListItem className={styles.header}>Lyf & heilsa</ListItem>
        {navigation.map(({ id, subNav }) => (
          <Fragment key={id}>
            <ListItem className={styles.category}>
              <ListItemText classes={{ primary: styles.categoryText }}>
                {id}
              </ListItemText>
            </ListItem>
            {subNav.map(({ text, path, icon }) => (
              <Link
                key={path}
                to={path}
                getProps={({ isCurrent, isPartiallyCurrent }) => ({
                  className: cn(styles.link, {
                    [styles['link--active']]:
                      path === '/' ? isCurrent : isPartiallyCurrent
                  })
                })}
              >
                <ListItem button className={styles.listItem}>
                  <ListItemIcon className={styles.listItemIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText classes={{ primary: styles.listItemText }}>
                    {text}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
          </Fragment>
        ))}
      </List>
    </Drawer>
  )
}

export default Navigator

import React, { FC, ReactElement, useCallback } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import ProductForm from '../components/ProductForm'
import Paperbase from '../components/Paperbase'
import Content from '../components/Content'
import NotFound from '../components/NotFound'
import QueryHandler from '../components/QueryHandler'
import { GET_PRODUCT_GROUP } from '../graphql/queries'
import { UPDATE_PRODUCT_GROUP } from '../graphql/mutations'
import { GetProductGroupQuery, UpdateProductGroupInput } from '../graphql/schema'

interface ProductEditProps extends RouteComponentProps {
  id: string
}

const ProductEdit: FC<ProductEditProps> = ({ id }) => {
  const [submit] = useMutation<UpdateProductGroupInput>(UPDATE_PRODUCT_GROUP, {
    update: (cache) => {
      cache.reset()
    },
    onCompleted: () => {
      window.history.back()
    }
  })

  const onSubmit = useCallback(async (input: UpdateProductGroupInput) => {
    await submit({ variables: { input } })
  }, [submit])

  const productQuery = useQuery(GET_PRODUCT_GROUP, {
    variables: { id }
  })

  const render = (r: GetProductGroupQuery): ReactElement => {
    const group = r.productGroup
    if (group == null) {
      return <NotFound />
    }

    return (
      <Content>
        <Box mb={3}>
          <Typography variant='h4'>Edit Product</Typography>
        </Box>
        <ProductForm
          initialValues={{
            id,
            title: group.title,
            description: group.description,
            shortDescription: group.shortDescription,
            usage: group.usage,
            ingredients: group.ingredients,
            tags: group.tags,
            products: group.products.map((p) => ({
              ...p,
              maxQuantity: '' + (p.maxQuantity ?? '')
            })),
            lyfOgHeilsa: group.lyfOgHeilsa,
            apotekarinn: group.apotekarinn
          }}
          onSubmit={(data) => onSubmit({ ...data, id })}
        />
      </Content>
    )
  }

  return (
    <Paperbase>
      <QueryHandler query={productQuery} render={render} />
    </Paperbase>
  )
}

export default ProductEdit

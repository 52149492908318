import React, { FC } from 'react'
import {
  Modal as MUIModal,
  ModalProps,
  Card,
  CardContent
} from '@material-ui/core'
import styles from './Modal.module.css'

const Modal: FC<ModalProps> = (props) => {
  return (
    <MUIModal {...props} className={styles.modal}>
      <div className={styles.container}>
        <Card className={styles.content}>
          <CardContent>{props.children}</CardContent>
        </Card>
      </div>
    </MUIModal>
  )
}

export default Modal

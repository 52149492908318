import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import Paperbase from '../components/Paperbase'
import { LOGIN_API_URL } from '../utils'
import { useAuthentication } from '../contexts/authentication'
import { useLocation } from '@reach/router'

const NotAuthorized: FC = () => {
  const { user } = useAuthentication()
  const { href } = useLocation()
  const loginUrl = `${LOGIN_API_URL}?backTo=${encodeURIComponent(href)}`

  return (
    <Paperbase>
      <Box
        height='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        {user ? (
          <Alert severity='error'>
            <AlertTitle>Unauthorized</AlertTitle>
            You are not authorized to view this page
          </Alert>
        ) : (
          <Alert severity='info'>
            <AlertTitle>Login required</AlertTitle>
            You need to <a href={loginUrl}>login to view this page</a>
          </Alert>
        )}
      </Box>
    </Paperbase>
  )
}

export default NotAuthorized

import React, { FC } from 'react'
import MuiPagination, { PaginationProps } from '@material-ui/lab/Pagination'
import { PaginationItem } from '@material-ui/lab'
import { Link } from '@reach/router'
import qs from 'qs'
import useQueryParams from '../hooks/useQueryParams'

const isValidPage = (page: number, totalPages: number): boolean => {
  return page != null && page >= 1 && page <= totalPages
}

const Pagination: FC<PaginationProps> = (props) => {
  const queryParams = useQueryParams()

  return (
    <MuiPagination
      renderItem={(itemProps) =>
        isValidPage(itemProps.page, props.count ?? 0) ? (
          <Link
            to={'?' + qs.stringify({ ...queryParams, page: itemProps.page })}
            className='inherit'
          >
            <PaginationItem {...itemProps} />
          </Link>
        ) : (
          <PaginationItem {...itemProps} />
        )}
      {...props}
    />
  )
}

export default Pagination

import React, { FC, useCallback, ChangeEvent } from 'react'
import { Grid, Button, IconButton } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloseIcon from '@material-ui/icons/Close'
import styles from './ImageUpload.module.css'

export interface Image {
  id?: string
  file?: File
  url: string
}

export interface ImageUploadProps {
  images: Image[]
  onChange: (images: Image[]) => void
}

const ImageUpload: FC<ImageUploadProps> = ({ images, onChange }) => {
  const onAddImages = (event: ChangeEvent<HTMLInputElement>): void => {
    const newImages = Array.from(event?.target.files ?? []).map(
      (file): Image => ({
        url: URL.createObjectURL(file),
        file
      })
    )

    onChange(images.concat(newImages))
  }

  const onRemove = useCallback(
    (i: number) => {
      onChange([...images.slice(0, i), ...images.slice(i + 1)])
    },
    [images, onChange]
  )

  return (
    <Grid container direction='column' spacing={3}>
      {images.length > 0 && (
        <Grid item>
          <Grid container justify='flex-start' alignItems='center' spacing={5}>
            {images.map((img, i) => (
              <Grid item key={img.url}>
                <Grid container direction='row' spacing={0} justify='flex-start'>
                  <Grid item justify='flex-end'>
                    <IconButton onClick={() => onRemove(i)}>
                      <CloseIcon color='action' />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <img src={img.url} alt='' className={styles.image} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id='image-upload-input'
          multiple
          type='file'
          onChange={onAddImages}
        />
        <label htmlFor='image-upload-input'>
          <Button
            variant='outlined'
            color='default'
            component='span'
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>
        </label>
      </Grid>
    </Grid>
  )
}

export default ImageUpload

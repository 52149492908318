import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import { Box, BoxProps, AppBar, Paper, Toolbar } from '@material-ui/core'
import styles from './Content.module.css'

export interface ContentProps {
  size?: 'auto' | 'large'
  topContent?: ReactNode
  contentBoxProps?: BoxProps
}

const Content: FC<ContentProps> = ({
  size,
  topContent,
  contentBoxProps,
  children
}) => {
  return (
    <Paper
      className={cn(styles.container, {
        [styles['container--wide']]: size === 'large'
      })}
    >
      {Boolean(topContent) && (
        <AppBar position='static' color='default' elevation={0}>
          <Toolbar>{topContent}</Toolbar>
        </AppBar>
      )}
      <Box px={3} py={5} {...contentBoxProps}>
        {children}
      </Box>
    </Paper>
  )
}

export default Content

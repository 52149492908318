import React, { FC } from 'react'
import { formatPrice } from '../utils'

export interface PriceProps {
  price?: number | null
  discountPrice?: number | null
  separator?: string | React.ReactElement
}

const Price: FC<PriceProps> = ({
  price,
  discountPrice,
  separator = ' '
}) => {
  if (price !== discountPrice) {
    return (
      <>
        <s>{formatPrice(price ?? -1)}</s>
        {separator}
        {formatPrice(discountPrice ?? -1)}
      </>
    )
  }

  return <>{formatPrice(price ?? -1)}</>
}

export default Price

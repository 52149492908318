import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { create } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { AuthenticationProvider } from './contexts/authentication'
import './index.css'
import App from './App'
import { GRAPHQL_API_URL } from './utils'

Bugsnag.start({
  apiKey: 'cb9fdbce82b803f5dbee48d6347bd015',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

const httpLink = createUploadLink({
  uri: GRAPHQL_API_URL,
  credentials: 'include'
})

const headersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // store id of lyf og heilsa kringlunni, required for fetching inventory status
      'X-Store': 'S015',
      'X-COMP': 'LYF_OG_HEILSA'
    }
  }
})

const apolloClient = new ApolloClient({
  link: headersLink.concat(httpLink as any),
  cache: new InMemoryCache()
})

// this is so we can override material-ui css in our own css modules
// https://material-ui.com/styles/advanced/#insertionpoint
const jss = create({
  ...jssPreset(),
  // material-ui-insertion-point is defined in public/index.html
  insertionPoint: document.getElementById('material-ui-insertion-point')!
})

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StylesProvider jss={jss}>
        <ApolloProvider client={apolloClient}>
          <AuthenticationProvider>
            <App />
          </AuthenticationProvider>
        </ApolloProvider>
      </StylesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

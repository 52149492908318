import React, { FC, useEffect } from 'react'
import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useAuthentication } from '../contexts/authentication'
import { useLocation } from '@reach/router'
import { LOGOUT_API_URL, redirect } from '../utils'
import { BUILD_FRONTEND } from '../graphql/mutations'
import { useMutation } from '@apollo/client'

interface HeaderProps {
  onDrawerToggle: () => void
}

const Header: FC<HeaderProps> = ({ onDrawerToggle }: HeaderProps) => {
  const { protocol, host } = useLocation()
  const { user } = useAuthentication()
  const [build, { data, loading, error }] = useMutation(BUILD_FRONTEND)

  useEffect(() => error && alert(error), [error])

  if (user == null) {
    return null
  }

  const renderBuildStatus = () => {
    if (loading) {
      return '...'
    }

    if (data && data.success === true) {
      return 'Deploy started. Changes will be live in about 5 minutes'
    }

    if (data && data.success === false) {
      return 'Failed to start deployment'
    }

    return (
      <Button
        onClick={() => build()}
        variant='outlined'
        color='inherit'
        size='small'
      >
        Deploy
      </Button>
    )
  }

  return (
    <AppBar position='relative' elevation={0}>
      <Toolbar>
        <Grid container spacing={3} alignItems='center'>
          <Hidden smUp>
            <Grid item>
              <IconButton onClick={onDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>{renderBuildStatus()}</Grid>
          <Grid item>
            {user.details?.name}{' '}
            <Button
              onClick={() =>
                redirect(LOGOUT_API_URL, [['backTo', `${protocol}//${host}`]])}
              variant='outlined'
              color='inherit'
              size='small'
            >
              Log out
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header

import React, { FC, useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useQuery, useApolloClient } from '@apollo/client'
import useDebouncedValue from '../hooks/useDebouncedValue'
import { GET_NAV_PRODUCT_LIST, GET_NAV_PRODUCT } from '../graphql/queries'
import { GetNavProductQuery, GetNavProductListQuery } from '../graphql/schema'

export type Product = NonNullable<GetNavProductQuery['navproduct']>
type ListProduct = GetNavProductListQuery['navproducts']['nodes'][0]

interface ProductSelectorProps {
  onSelect: (p: Product) => void
}

const ProductSelector: FC<ProductSelectorProps> = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState('')
  const searchTerm = useDebouncedValue(inputValue)
  const [options, setOptions] = useState<ListProduct[]>([])
  const client = useApolloClient()

  const query = useQuery(GET_NAV_PRODUCT_LIST, {
    skip: searchTerm === '',
    variables: {
      input: {
        query: searchTerm,
        perPage: 20
      }
    }
  })

  useEffect(() => {
    if (!query.loading) {
      setOptions(query.data?.navproducts.nodes ?? [])
    }
  }, [query])

  return (
    <Autocomplete
      value={null}
      inputValue={inputValue}
      onChange={(_e, selected) => {
        setInputValue('')
        if (selected) {
          client
            .query<GetNavProductQuery>({
            query: GET_NAV_PRODUCT,
            variables: { id: selected.id }
          })
            .then(({ data: { navproduct } }) => {
              navproduct && onSelect(navproduct)
            })
        }
      }}
      getOptionLabel={(o: ListProduct) => o.titleInNav}
      getOptionSelected={() => false}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Add product'
          variant='filled'
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
    />
  )
}

export default ProductSelector

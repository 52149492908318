import React, { FC } from 'react'
import { RouteComponentProps, Router } from '@reach/router'
import ProductList from './pages/ProductList'
import ProductNew from './pages/ProductNew'
import ProductEdit from './pages/ProductEdit'
import NavProductList from './pages/NavProductList'
import NotAuthorized from './pages/NotAuthorized'
import { useAuthentication } from './contexts/authentication'
import Paperbase from './components/Paperbase'
import Loading from './components/Loading'

const AuthenticationCheck: FC<RouteComponentProps> = ({ children }) => {
  const { user, loading } = useAuthentication()

  if (loading) {
    return (
      <Paperbase>
        <Loading />
      </Paperbase>
    )
  }

  if (user == null || !user.isAdmin) {
    return <NotAuthorized />
  }

  return <>{children}</>
}

const App: FC = () => {
  return (
    <Router>
      <AuthenticationCheck default>
        <ProductList path='/' />
        <ProductNew path='/products/new' />
        <ProductEdit path='/products/edit/:id' id='' />
        <NavProductList path='/nav-products' />
      </AuthenticationCheck>
    </Router>
  )
}

export default App

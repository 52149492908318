import React, { FC } from 'react'
import capitalize from 'lodash/capitalize'
import useQueryParams from '../hooks/useQueryParams'
import { mergeQueryParams } from '../utils'
import { Select, FormControl, InputLabel } from '@material-ui/core'

const SelectFilter: FC<{
  label: string
  name: string
  items: Array<{ text: string, value?: string, count?: number }>
}> = ({ label, name, items }) => {
  const qs = useQueryParams()

  return (
    <FormControl fullWidth className='form-control'>
      <InputLabel htmlFor={'id-' + name}>{label}</InputLabel>
      <Select
        native
        inputProps={{
          name,
          id: 'id-' + name
        }}
        value={qs[name] || ''}
        onChange={(e) => {
          mergeQueryParams({
            [name]: (e.target.value as string) || undefined,
            page: undefined
          })
        }}
      >
        <option value='' />
        {items.map(({ text, value, count }) => (
          <option key={text} value={value ?? text}>
            {capitalize(text)}
            {count && ` (${count})`}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectFilter

import { gql } from '@apollo/client'

const pageInfoFragment = gql`
  fragment PageInfoFields on PageInfo {
    page
    totalResults
    totalPages
    hasNextPage
    hasPrevPage
  }
`

export const GET_VIEWER = gql`
  query GetViewer {
    viewer {
      id
      isAdmin
      details {
        name
      }
    }
  }
`

export const GET_CATEGORY_LIST = gql`
  query GetCategoryList {
    categories {
      id
      title
    }
  }
`

export const GET_PRODUCT_GROUP_LIST = gql`
  query GetProductGroupList($input: ProductsInput) {
    productGroups(input: $input) {
      nodes {
        id
        title
        price: lyfOgHeilsaFullPrice
        discountPrice: lyfOgHeilsaDiscountPrice
        updatedAt
        createdAt
        lyfOgHeilsa
        apotekarinn
        tags
        category {
          id
          title
          slug
        }
        subcategory {
          id
          title
        }
        brand {
          id
          title
        }
        images {
          thumbnailUrl
        }
        products {
          id
          title
        }
      }
      pageInfo {
        ...PageInfoFields
      }
      aggregations {
        categories {
          text
          count
          category {
            id
            title
          }
        }
        subcategories {
          text
          count
          subcategory {
            id
            title
          }
        }
        brands {
          text
          count
          brand {
            id
            title
          }
        }
      }
    }
  }
  ${pageInfoFragment}
`

export const GET_PRODUCT_GROUP = gql`
  query GetProductGroup($id: ID!) {
    productGroup(id: $id) {
      id
      title
      description
      shortDescription
      usage
      ingredients
      tags
      updatedAt
      createdAt
      lyfOgHeilsa
      apotekarinn
      products {
        id
        title
        titleInNav
        price: lyfOgHeilsaFullPrice
        discountPrice: lyfOgHeilsaDiscountPrice
        maxQuantity
        inventoryCount
        navCategoryCode
        navProductGroup
        images {
          id
          url: thumbnailUrl
        }
      }
      subcategory {
        id
        title
      }
    }
  }
`

export const GET_NAV_PRODUCT_LIST = gql`
  query GetNavProductList($input: NavproductsInput) {
    navproducts(input: $input) {
      nodes {
        id
        title
        titleInNav
        price: lyfOgHeilsaFullPrice
        discountPrice: lyfOgHeilsaDiscountPrice
        navFamilyCode
        navCategoryCode
        navProductGroup
        productGroup {
          id
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${pageInfoFragment}
`

export const GET_NAV_PRODUCT = gql`
  query GetNavProduct($id: ID!) {
    navproduct(id: $id) {
      id
      title
      titleInNav
      price: lyfOgHeilsaFullPrice
      discountPrice: lyfOgHeilsaDiscountPrice
      maxQuantity
      inventoryCount
      navCategoryCode
      navProductGroup
    }
  }
`

export const GET_TAG_LIST = gql`
  query GetTagList {
    tags {
      text
    }
  }
`

export const GET_METADATA = gql`
  query GetMetadata {
    navMetadata {
      familyCodes {
        text
        count
      }
      categoryCodes {
        text
        count
      }
      groups {
        text
        count
      }
    }
  }
`

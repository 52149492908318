import React, { FC } from 'react'
import { Typography, Box } from '@material-ui/core'

interface NotFoundProps {
  title?: string
}

const NotFound: FC<NotFoundProps> = ({ title = 'Page not found' }) => (
  <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
    <Typography variant='h2' component='h2'>
      {title}
    </Typography>
  </Box>
)

export default NotFound

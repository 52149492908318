import React, { FC, ReactElement, useCallback } from 'react'
import { RouteComponentProps } from '@reach/router'
import useQueryParams from '../hooks/useQueryParams'
import ProductForm from '../components/ProductForm'
import Paperbase from '../components/Paperbase'
import Content from '../components/Content'
import { Box, Typography } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { GET_NAV_PRODUCT } from '../graphql/queries'
import { CREATE_PRODUCT_GROUP } from '../graphql/mutations'
import {
  GetNavProductQuery,
  CreateProductGroupInput
} from '../graphql/schema'
import QueryHandler from '../components/QueryHandler'
import NotFound from '../components/NotFound'

const ProductNew: FC<RouteComponentProps> = () => {
  const { id } = useQueryParams()

  const [submit] = useMutation<CreateProductGroupInput>(CREATE_PRODUCT_GROUP, {
    update: (cache) => {
      cache.reset()
    },
    onCompleted: () => {
      window.history.back()
    }
  })

  const onSubmit = useCallback(
    async (formState: CreateProductGroupInput) => {
      await submit({ variables: { input: formState } })
    },
    [submit]
  )

  const productQuery = useQuery(GET_NAV_PRODUCT, {
    variables: { id }
  })

  const render = ({ navproduct }: GetNavProductQuery): ReactElement => {
    if (navproduct == null) {
      return <NotFound />
    }

    return (
      <Content>
        <Box mb={3}>
          <Typography variant='h4'>New Product</Typography>
        </Box>
        <ProductForm
          initialValues={{
            title: navproduct.titleInNav,
            products: [
              {
                ...navproduct,
                title: navproduct.titleInNav,
                maxQuantity: '',
                images: []
              }
            ]
          }}
          onSubmit={onSubmit}
        />
      </Content>
    )
  }

  return (
    <Paperbase>
      <QueryHandler query={productQuery} render={render} />
    </Paperbase>
  )
}

export default ProductNew

import React, { FC, useState, useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  TextField,
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ProductSelector from './ProductSelector'
import ImageUpload, { Image } from './ImageUpload'
import Price from './Price'
import { GET_TAG_LIST } from '../graphql/queries'
import { useQuery } from '@apollo/client'
import { GetTagListQuery, CreateProductGroupInput } from '../graphql/schema'

const Heading: FC = ({ children }) => (
  <Box paddingTop={2} paddingBottom={1}>
    <Typography variant='h6' display='block'>
      {children}
    </Typography>
  </Box>
)

export interface ProductFormState {
  id?: string
  title: string
  description: string
  shortDescription: string
  usage: string
  ingredients: string
  tags: string[]
  products: Array<{
    id: string
    title: string
    inventoryCount: number
    maxQuantity: string
    images: Image[]
  }>
  lyfOgHeilsa: boolean
  apotekarinn: boolean
}

const tryParseInt = (s: string): number | null => {
  const n = parseInt(s, 10)
  return isNaN(n) ? null : n
}

const newFormState = (
  initialValues: Partial<ProductFormState> = {}
): ProductFormState => ({
  title: '',
  description: '',
  shortDescription: '',
  usage: '',
  ingredients: '',
  tags: [],
  lyfOgHeilsa: true,
  apotekarinn: false,
  ...initialValues,
  products: (initialValues.products ?? []).map((p) => ({
    ...p,
    maxQuantity: '' + (p.maxQuantity ?? '')
  }))
})

const formStateToServerParams = (
  form: ProductFormState
): CreateProductGroupInput => ({
  ...form,
  products: form.products.map((p) => ({
    ...p,
    maxQuantity: tryParseInt(p.maxQuantity),
    images: p.images.map(({ id, file }, position) => ({
      id,
      file,
      position
    }))
  }))
})

const useTags = (): string[] => {
  const tags = useQuery<GetTagListQuery>(GET_TAG_LIST)
  return useMemo(() => (tags.data?.tags ?? []).map((x) => x.text), [tags])
}

interface ProductFormProps {
  initialValues: Partial<ProductFormState>
  onSubmit: (fs: CreateProductGroupInput) => Promise<void>
}

const ProductForm: FC<ProductFormProps> = ({ initialValues, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const tags = useTags()
  const { register, handleSubmit, errors, control } = useForm<ProductFormState>(
    { defaultValues: newFormState(initialValues) }
  )

  const products = useFieldArray({
    control,
    name: 'products'
  })

  const handleOnSubmit = useCallback(
    async (form: ProductFormState) => {
      if (!isSubmitting) {
        setIsSubmitting(true)
        try {
          await onSubmit(formStateToServerParams(form))
        } catch (e) {
          alert('Eitthvað for úrskeiðis. Reyndu aftur seinna.')
          throw e
        } finally {
          setIsSubmitting(false)
        }
      }
    },
    [isSubmitting, setIsSubmitting, onSubmit]
  )

  const required = 'This field is required'

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required })}
            name='title'
            label='Title'
            fullWidth
            error={Boolean(errors.title)}
            helperText={errors.title?.message}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required })}
            name='description'
            label='Description'
            multiline
            fullWidth
            rows={15}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register()}
            name='shortDescription'
            label='Short description'
            multiline
            fullWidth
            rows={4}
            error={Boolean(errors.shortDescription)}
            helperText={errors.shortDescription?.message}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register()}
            name='usage'
            label='Usage instructions'
            multiline
            fullWidth
            rows={4}
            error={Boolean(errors.usage)}
            helperText={errors.usage?.message}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register()}
            name='ingredients'
            label='Ingredients'
            multiline
            fullWidth
            rows={4}
            error={Boolean(errors.ingredients)}
            helperText={errors.ingredients?.message}
            variant='filled'
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='tags'
            render={({ onChange, value }) => (
              <Autocomplete
                multiple
                freeSolo
                options={tags}
                value={value}
                onChange={(_e, newTags) => onChange(newTags)}
                renderInput={(params) => (
                  <TextField {...params} label='Tags' variant='filled' />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='lyfOgHeilsa'
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                    name='lyfOgHeilsa'
                    color='primary'
                  />
                }
                label='Lyf & Heilsa'
              />
            )}
          />
          <Controller
            control={control}
            name='apotekarinn'
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                    name='apotekarinn'
                    color='primary'
                  />
                }
                label='Apótekarinn'
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Heading>Products</Heading>
          {products.fields.map((p, index) => (
            <Grid key={p.id} container spacing={2} alignItems='center'>
              <Grid item xs>
                <Card variant='outlined'>
                  <CardContent>
                    <input
                      ref={register({ required })}
                      type='hidden'
                      name={`products[${index}].id`}
                      value={p.id}
                    />
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                      pb={2}
                    >
                      <Typography variant='body1'>{p.titleInNav}</Typography>
                      <Box display='flex' justifyContent='flex-end'>
                        <Box ml={1}>
                          <Typography color='textSecondary' variant='body2'>
                            id: {p.id}
                          </Typography>
                        </Box>
                        <Box ml={1}>
                          <Typography color='textSecondary' variant='body2'>
                            price:{' '}
                            <Price
                              price={p.price}
                              discountPrice={p.discountPrice}
                            />
                          </Typography>
                        </Box>
                        <Box ml={1}>
                          <Typography color='textSecondary' variant='body2'>
                            inventory: {p.inventoryCount}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box pb={2}>
                      <Controller
                        control={control}
                        name={`products[${index}].title`}
                        rules={{ required }}
                        defaultValue={p.title}
                        render={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label='Title'
                            size='small'
                            variant='filled'
                            color='primary'
                            error={Boolean(errors.products?.[index]?.title)}
                            helperText={
                              errors.products?.[index]?.title?.message
                            }
                          />
                        )}
                      />
                    </Box>
                    <Controller
                      control={control}
                      name={`products[${index}].maxQuantity`}
                      defaultValue={p.maxQuantity ?? ''}
                      rules={{
                        pattern: {
                          value: /^\d*$/,
                          message: 'Must be a number'
                        }
                      }}
                      render={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label='Max quantity'
                          size='small'
                          variant='filled'
                          color='primary'
                          placeholder='Unlimited'
                          error={Boolean(errors.products?.[index]?.maxQuantity)}
                          helperText={
                            errors.products?.[index]?.maxQuantity?.message
                          }
                        />
                      )}
                    />
                    <Box pb={2}>
                      <Heading>Images</Heading>
                      <Controller
                        control={control}
                        name={`products[${index}].images`}
                        render={({ onChange, value }) => (
                          <ImageUpload images={value} onChange={onChange} />
                        )}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid>
                <Box px={2}>
                  <HighlightOffIcon
                    cursor='pointer'
                    onClick={() => products.remove(index)}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <ProductSelector
            onSelect={(product) => {
              products.append({
                ...product,
                title: product.titleInNav
              })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box paddingTop={2}>
            {isSubmitting ? (
              <CircularProgress size={30} />
            ) : (
              <Button type='submit' color='primary' variant='contained'>
                {initialValues.id == null ? 'Create' : 'Update'}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default ProductForm

import { useMemo } from 'react'
import { useLocation } from '@reach/router'
import qs from 'qs'

export interface QueryParams {
  [k: string]: string
}

export default function useQueryParams (): QueryParams {
  const { search } = useLocation()
  return useMemo((): QueryParams => {
    const r: QueryParams = {}
    for (const [k, v] of Object.entries(qs.parse(search.substring(1)))) {
      if (typeof v === 'string') {
        r[k] = v
      }
    }
    return r
  }, [search])
}

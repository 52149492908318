import React, { ReactElement } from 'react'
import { QueryResult } from '@apollo/client'
import { Box } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import Loading from './Loading'

export interface QueryHandlerProps<T> {
  query: QueryResult<T>
  render: (data: T) => ReactElement
  renderLoading?: () => ReactElement
  renderError?: (error: Error) => ReactElement
}

const defaultRenderLoading = (): ReactElement => <Loading />

const defaultRenderError = (error: Error): ReactElement => (
  <Box height='100%' display='flex' justifyContent='center' alignItems='center'>
    <Alert severity='error'>
      <AlertTitle>{error.message}</AlertTitle>
      <code>{error.stack}</code>
    </Alert>
  </Box>
)

export default function QueryHandler<T> ({
  query,
  render,
  renderLoading = defaultRenderLoading,
  renderError = defaultRenderError
}: QueryHandlerProps<T>): ReactElement {
  if (query.loading) {
    return renderLoading()
  }

  if (query.error != null) {
    return renderError(query.error)
  }

  if (query.data === undefined) {
    return renderError(new Error('Unexpected graphql response: undefined'))
  }

  return render(query.data)
}

import qs from 'qs'
import { format } from 'date-fns'
import { navigate } from '@reach/router'

const rootUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:4000'

export const GRAPHQL_API_URL = rootUrl + '/api/graphql'
export const LOGIN_API_URL = rootUrl + '/login/island-loh'
export const LOGOUT_API_URL = rootUrl + '/api/logout'

export const formatPrice = (price: number): string => {
  return price.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, '$1.') + ' kr.'
}

export const formatDate = (date: Date | number): string => {
  return format(new Date(date), 'PP')
}

export const formatTime = (date: Date | number): string => {
  return format(new Date(date), 'hh:mm')
}

export const formateDateTime = (date: Date | number): string => {
  return `${formatDate(date)} ${formatTime(date)}`
}

export const mergeQueryParams = (newParams: {
  [k: string]: string | undefined
}): void => {
  const params = qs.parse(window.location.search.substring(1))
  const s = qs.stringify({ ...params, ...newParams })
  navigate(window.location.pathname + (s && '?' + s), { replace: true })
}

export const redirect = (
  action: string,
  params: Array<[string, string]> = [],
  { method = 'POST' } = {}
): void => {
  const form = document.createElement('form')
  form.action = action
  form.method = method
  document.body.appendChild(form)

  params.forEach(([name, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    form.appendChild(input)
  })

  form.submit()
}

import { gql } from '@apollo/client'

const errorFields = gql`
  fragment errorFields on ErrorMessage {
    message
  }
`

export const CREATE_PRODUCT_GROUP = gql`
  mutation CreateProductGroup($input: CreateProductGroupInput!) {
    createProductGroup(input: $input) {
      ... on ProductGroup {
        id
      }
      ... on ErrorMessage {
        ...errorFields
      }
    }
  }
  ${errorFields}
`

export const UPDATE_PRODUCT_GROUP = gql`
  mutation UpdateProductGroup($input: UpdateProductGroupInput!) {
    updateProductGroup(input: $input) {
      ... on ProductGroup {
        id
      }
      ... on ErrorMessage {
        ...errorFields
      }
    }
  }
  ${errorFields}
`

export const JOIN_PRODUCT_GROUPS = gql`
  mutation JoinProductGroups($input: JoinProductGroupsInput!) {
    joinProductGroups(input: $input) {
      ... on ProductGroup {
        id
      }
      ... on ErrorMessage {
        ...errorFields
      }
    }
  }
  ${errorFields}
`

export const BUILD_FRONTEND = gql`
  mutation BuildFrontend {
    success: buildFrontend
  }
`

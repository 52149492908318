import React, { FC, createContext, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { GET_VIEWER } from '../graphql/queries'
import { GetViewerQuery } from '../graphql/schema'

export interface AuthContext {
  user: GetViewerQuery['viewer'] | null
  loading: boolean
}

const AuthenticationContext =
  createContext<AuthContext>({ user: null, loading: false })

export const useAuthentication = (): AuthContext =>
  useContext(AuthenticationContext)

export const AuthenticationProvider: FC = ({ children }) => {
  const query = useQuery<GetViewerQuery>(GET_VIEWER)

  const contextValue = {
    user: query.data?.viewer ?? null,
    loading: query.loading
  }

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  )
}

import React, { FC, useState, ReactElement } from 'react'
import { Link, RouteComponentProps } from '@reach/router'
import { useQuery } from '@apollo/client'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  TextField,
  Box,
  Button,
  TableSortLabel
} from '@material-ui/core'
import Pagination from '../components/Pagination'
import SearchIcon from '@material-ui/icons/Search'
import Paperbase from '../components/Paperbase'
import Content from '../components/Content'
import SelectFilter from '../components/SelectFilter'
import QueryHandler from '../components/QueryHandler'
import Price from '../components/Price'
import { GET_NAV_PRODUCT_LIST } from '../graphql/queries'
import { GetNavProductListQuery } from '../graphql/schema'
import usePageNumber from '../hooks/usePageNumber'
import useSearchTerm from '../hooks/useSearchTerm'
import useQueryParams from '../hooks/useQueryParams'
import useMetadata from '../hooks/useMetadata'

const columns = [
  { name: 'Id', key: 'ID' },
  { name: 'Title', key: 'TITLE' },
  { name: 'Category', key: 'CATEGORY' },
  { name: 'Group', key: 'GROUP' },
  { name: 'Family', key: 'FAMILY' },
  { name: 'Price', key: 'PRICE' },
  { name: '' }
]

const NavProductList: FC<RouteComponentProps> = () => {
  const qs = useQueryParams()
  const metadata = useMetadata()
  const page = usePageNumber()
  const [searchTerm, setSearchTerm] = useSearchTerm()
  const [sort, setSort] = useState({
    name: 'Title',
    key: 'TITLE',
    asc: true
  })

  const onChangeSort = ({ name, key }: { name: string, key: string }): void => {
    setSort({ name, key, asc: key === sort.key ? !sort.asc : true })
  }

  const query = useQuery(GET_NAV_PRODUCT_LIST, {
    variables: {
      input: {
        page,
        perPage: 25,
        imported: qs.imported == null ? null : qs.imported === '1',
        query: searchTerm,
        navFamilyCode: qs.family,
        navCategoryCode: qs.category,
        navGroupId: qs.group,
        sort: `${sort.key}_${sort.asc ? 'ASC' : 'DESC'}`
      }
    }
  })

  const topContent = (
    <Grid container spacing={2} alignItems='center'>
      <Grid item>
        <SearchIcon color='inherit' />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          defaultValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Search by product id or description'
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
    </Grid>
  )

  const render = (data: GetNavProductListQuery): ReactElement => {
    const products = data.navproducts.nodes
    const pageInfo = data.navproducts.pageInfo

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.name}>
                  {col.key ? (
                    <TableSortLabel
                      active={sort.key === col.key}
                      direction={sort.asc ? 'desc' : 'asc'}
                      onClick={() => col.key && onChangeSort(col)}
                    >
                      {col.name}
                    </TableSortLabel>
                  ) : (
                    col.name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.id}</TableCell>
                <TableCell>{product.titleInNav}</TableCell>
                <TableCell>{product.navCategoryCode}</TableCell>
                <TableCell>{product.navProductGroup}</TableCell>
                <TableCell>{product.navFamilyCode}</TableCell>
                <TableCell align='right' className='nowrap'>
                  <Price
                    price={product.price}
                    discountPrice={product.discountPrice}
                    separator={<br />}
                  />
                </TableCell>
                <TableCell align='right'>
                  {product.productGroup ? (
                    <Link to={`/products/edit/${product.productGroup.id}`}>
                      exists
                    </Link>
                  ) : (
                    <Link
                      className='inherit'
                      to={`/products/new?id=${product.id}`}
                    >
                      <Button variant='contained' color='primary'>
                        Create
                      </Button>
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box paddingTop={5} display='flex' justifyContent='center'>
          <Pagination page={page} count={pageInfo.totalPages} />
        </Box>
      </TableContainer>
    )
  }

  return (
    <Paperbase>
      <Content
        topContent={topContent}
        contentBoxProps={{ paddingTop: 2 }}
        size='large'
      >
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          spacing={3}
        >
          <Grid item xs>
            <SelectFilter
              label='Exists in products'
              name='imported'
              items={[
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' }
              ]}
            />
          </Grid>
          <Grid item xs>
            <SelectFilter
              label='Category'
              name='category'
              items={metadata.categoryCodes}
            />
          </Grid>
          <Grid item xs>
            <SelectFilter label='Group' name='group' items={metadata.groups} />
          </Grid>
          <Grid item xs>
            <SelectFilter
              label='Family'
              name='family'
              items={metadata.familyCodes}
            />
          </Grid>
        </Grid>
        <Box paddingTop={3}>
          <QueryHandler query={query} render={render} />
        </Box>
      </Content>
    </Paperbase>
  )
}

export default NavProductList
